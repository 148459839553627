import { LoginPage, Sign, SendEmail } from 'components/login';
import React, { useState } from 'react';

import styles from './login.module.scss';

const Login: React.FC = () => {
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [forgotPass, setForgotPass] = useState<boolean>(false);

  return (
    <div className={` flex flex-row justify-center p-8 ${styles.container}`}>
      {forgotPass ? (
        <>
          <SendEmail setForgotPass={setForgotPass} />
        </>
      ) : (
        <>
          {isNewUser ? (
            <Sign setIsNewUser={setIsNewUser} />
          ) : (
            <LoginPage setIsNewUser={setIsNewUser} setForgotPass={setForgotPass} />
          )}
        </>
      )}
    </div>
  );
};

export default Login;
